body {
    font-family: 'Josefin Sans', sans-serif;
}
#container {
    width: 100%;
    height: 100%;
    background-color: #ececec;
    margin: 0 auto;
    padding: 50px 50px 50px 49px; 
    position: relative;
    border-bottom: 5px solid #ff6666;
   
}



h1{
    color: navy;
    float: left;
    padding-left: 15px;
}



h2 {
    color: #ff6666;
    background-color: white;
    width: 100%;
    padding: 0px 0px 10px 0px;
    margin: 0px 0px 10px 0px;
    border-bottom: 2px solid #ececec;

}

.profilePic {
    width: 300px;
    height: 450px;
    padding: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
}

.icon {
    color: #2a458e;
    padding-top: 5px;
    padding-right: 15px;
    float: right;
}



a:hover {
    color: #c09793 !important;
  }



.main-content {
    padding: 30px;
    margin-bottom: 75%;
    padding-bottom: 30px; 
    background-color: white;
    position: relative;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    border: solid 2px navy;

}

footer {
    background-color: navy;
    color: white;
    text-align: center;
    width: 100%;
   
}

.copyright {
    margin-bottom: 0px; 
    padding-top: 20px;
    
}

@media screen and (max-width: 768px) {

    /* .links-wrapper {
        margin-right: 10%;
    } */

    .icon-small {
        position: absolute;
        top: 1%;
        justify-content: right;
        width: 200px;
    }

    #about-text, #resume-text, #portfolio-text {
        margin-top: 20px;
        font-size: 15px;
       
    }

    #about-text {
        top: 16%;
        left: 8%;
    }

    #resume-text {
        position: absolute;
        top: 16%;
        left: 65%;
    }

    #portfolio-text {
        position: absolute;
        top: 19%;
        left: 44%;
    }

    .hero-text {
        left: 10%;
    }

    .name {
        text-align: left !important;
        font-size: 20px !important;
        margin-left: 10%;
    }

    .about{
        background-color:rgba(0,0,0,0.9);
        border-radius: 0px 0px 10px 10px;
        height: 165%;
        width: 100%;
        position: absolute;
        top: -135px;
        z-index: 3;
        font-size: 10px;
    }

    .about-p {
        width: 100%;
        height: 100%;
        color: white;
        position: absolute;
        top: 6%;
        left: 3%;
        font-size: 12px;
        padding: 20px;
        padding-top: 2px;
    }

    .button-header {
        padding-top: 20%;
      }

    .img-responsive {
        padding-top: 60%;
        width: 100%;
        height: 100%;
    }

    .project-description {
        margin-top: 36%;
        position: absolute;
        background-color:rgba(0,0,0,0);
        color: rgba(0,0,0,0);
        top: 17%;
        left: 0%;
        height: 91%;
        width: 100%;
        padding-top: 5% !important;
        margin-right: 2%;
        padding-right: 5%;
        font-size: 13px !important;
      
        /* margin-bottom: 15px; */
      }

      #once-header, #work-header {
          font-size: 30px ;
      }

      .btn-close-resume {
        position: relative;
        top: -7%;
        left: -11%;
    
    }

    .mobile-download {
        color: white;
        z-index: 2;
        margin-top: 40px;
        width: 230px;
    }

    #mobile-download-here {
        color: #c09793;
        z-index: 2;
    }



    .resume-no-show {
        display: none;
    }
      
}


@media screen and (max-width: 1316px) and (min-width: 768px) {

    .icon-small {
        position: relative;
        top: -375px;
        left: 39%;
        width: 20px;
        height: 50px;
    }

    #about-text, #resume-text, #portfolio-text {
        margin-top: 50px;
       
    }


    /* #resume-text {
       
    }

    #portfolio-text {
        
    } */

    .hero-text {
        left: 10%;
    }

    .name {
        margin-top: 200px !important;
        text-align: left !important;
        font-size: 30px !important;
        margin-left: 10%;
    }

    .about{
        background-color:rgba(0,0,0,0.9);
        border-radius: 0px 0px 10px 10px;
        height: 165%;
        width: 100%;
        position: absolute;
        top: -135px;
        z-index: 3;
        font-size: 10px;
    }

    .about-p {
        width: 100%;
        height: 100%;
        color: white;
        position: absolute;
        top: 6%;
        left: 3%;
        font-size: 12px;
        padding: 20px;
        padding-top: 2px;
    }

    .button-header {
        padding-top: 20%;
      }

    .img-responsive {
        padding-top: 60%;
        width: 100%;
        height: 100%;
    }

    .project-description {
        margin-top: 36%;
        position: absolute;
        background-color:rgba(0,0,0,0);
        color: rgba(0,0,0,0);
        top: 17%;
        left: 0%;
        height: 91%;
        width: 100%;
        padding-top: 5% !important;
        margin-right: 2%;
        padding-right: 5%;
        font-size: 13px !important;
      
        /* margin-bottom: 15px; */
      }

      #once-header, #work-header {
          font-size: 30px ;
      }

   
  

    


}

@media screen and (min-width: 1622px) {

    .icon-small {
        position: relative;
        top: 1px;
        left: 15%;
        width: 200px;
        height: 50px;
        color: purple;
    }
}