.links {
    position: absolute;
    /* top: 60%; */
    left: 15%;
    font-size: 20px;
    color:   #c09793 ; 
  }

  .links:hover{
    color: #2a458e !important;

  }

  #about-text {
    top: 60%; 
  }

  #portfolio-text {
      top: 66%;
      margin: 0px;
      padding: 0px;
      left: 20%;
  }

  #portfolio-text:hover {
      color: #2a458e !important;
  }

  #resume-text {
    top: 70%;
}


  .side {
      background-color: #c7cdd3;
      background-color: white;
  }

  .icon-content {
    color: rgba(0,0,0,0.6);
    font-size: 1px;
  }

  .div-links:hover {
    color: #c09793;
  }

  #email-fa {
    position: relative;
    top:1%;
    left: 4%;
    height: 4px;
  }

  .git-link {
    color: white
  }
  .git-link:hover {
    color: #c09793
  }