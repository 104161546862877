/* The hero image */
.banner {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .hero-text {
    text-align: center;
    position: absolute;
    top: 10%;
    left: 45%;
    font-size: 30px;
    color: white;
  }

 
#show {
    display: block;
}

#hide {
    display: none;
}

.about{
    background-color:rgba(0,0,0,0.6);
    border-radius: 0px 0px 10px 10px;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
}

.btn-close, .btn-close-resume {
    color: white;
    background-color: rgba(0,0,0,0);
    border-color:  rgba(0,0,0,0);;
    font-size: 32px;
}

.btn-close-resume {
    position: relative;
    top: -77%;
    left: -11%;

}

.mobile-download, #mobile-download-here {
    color: rgba(0,0,0,0);
    z-index: -1;
}



.resume{
    background-color:rgba(0,0,0,0.6);
    border-radius: 0px 0px 0px 0px;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    /* left: 30%; */
    padding-left: 10%;
    z-index: 3;
    /* opacity: 3; */
}

.resume-no-show {
    position: absolute;
    top: 2%;
    left: 5%;
}

.about-p {
    width: 750px;
    color: white;
    position: absolute;
    top: 20%;
    left: 3%;
    font-size: 20px;
}
