#container {
    width: 100%;
    height: 100%;
    background-color: #ececec;
    margin: 0 auto;
    padding: 50px 50px 50px 49px; 
    position: relative;
    border-bottom: 5px solid #ff6666;
}

.main-content {
    padding: 30px;
    margin-bottom: 5%;
    padding-bottom: 30px; 
    background-color: white;
    position: relative;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    border: solid 2px navy;

}

.name {
  text-align: center;
  margin-top: 100px;
  font-size: 50px;
  position: relative;
  top: 10%;
  left: 1%; 
  z-index: 3;
}


h2 {
    color: #ff6666;
    background-color: white;
    width: 100%;
    padding: 0px 0px 10px 0px;
    margin: 0px 0px 10px 0px;
    border-bottom: 2px solid #ececec;

}


  .btnstyle {
      width: 100%;
      background-color: #2a458e ;
      color: #ffffff;
      border-radius: 0%;
  }

  .button-header {
    font-size: 45px;
    /* margin-right: 20%; */
  }

  .btnstyle:hover {
    color: #c09793!important;
  }

  .img-wrapper {
    position: relative;
    margin: 0px;

   }

   .image-style {
     background-color: #2a458e ;
   }

   .web-links {
     color: white !important;
   }

   .project-description {
    position: absolute;
    background-color:rgba(0,0,0,0);
    color: rgba(0,0,0,0);
    top: 18%;
    left: 0%;
    height: 91%;
    width: 100%;
    padding-top: 15%;
    font-size: 25px;
  }

  .dropdown-style {
    position: absolute;
    top: 2%;
    left: 1%;
  }

  /* .hide-description {
    display: none;
  }

  .project-description:hover + .hide-description {
    display: show
  } */
  
  .project-description:hover {
    position: absolute;
    background-color:rgba(0,0,0,0.7);
    color: white;
    top: 18%;
    left: 0%;
    height: 83%;
    width: 100%;
    padding-top: 10%;
    font-size: 25px;
  } 
   
  .img-responsive {
    width: 100%;
    height: 100%;
  }
  
  .img-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }
  
  .img-overlay:before {
    content: ' ';
    display: block;
    height: 0%;
  }
  
  footer {
    background-color: #2a458e ;
    color: white;
    text-align: center;
   
}
  
.copyright {
    margin-bottom: 0px; 
    padding: 20px;
    
}

.btn-lock {
  background-color: rgba(0,0,0,0);
  border-color: rgba(0,0,0,0);
  color: white;
  position: relative;
  top: 1%;
}

.btn-lock:hover {
  color:#c09793;
}



.drop {
  color: #c09793;
  background-color: #2a458e;
  border-color: #2a458e;
  padding-top: 5px;
  margin-right: 20px;
  float: left;
  font-size: 30px;
}

.drop:hover {
  background-color: #c09793;
  color: #2a458e;
}

.drop:focus {
  background-color: #c09793;
  color: #2a458e;
}

.dropdown-menu {
  background-color: white;
  height: 120px;
  width: 300px;
}



.show {
  display: block;
}

.hide {
  display: none;
}
